<style scoped>
</style>
<template>
  <div v-if="showModal && showDept">
    <Modal
      v-model="showChooseDept"
      fullscreen
      @onCancel="cancel"
      @onOk="chooseDept"
      :closable="false"
    >
      <p slot="header" style="text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>选择单位</span>
      </p>
      <div>
        <Tree :data="data" @on-select-change="deptChange"></Tree>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="info" size="large" @click="chooseDept">确定</Button>
        <Button type="default" size="large" @click="cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { wxConPost, getUserPm } from "../api/httpApi";

import { getAllDept, setAllDept } from "../api/cacheDept";

export default {
  name: "deptTreeCmp",
  data() {
    return {
      data: [],
      curDept: {},

      showDept: false,
      showChooseDept: false,
    };
  },
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async getPmUserTree() {
      try {
        const data = getAllDept();
        if (data) {
          this.data = data;
        }
        const ret = await wxConPost("/services/gttt-user-mgt/dept/getPmDept", {
          onlyNeedTree: true,
        });
        // 全局缓存部门，为避免长时间获取不到部门，本地暂时缓存部门
        this.data = ret.ret;
        if (this.data.length > 0) {
          this.data[0]["expand"] = true;
        }
        setAllDept(this.data);
      } catch (error) {
        // 查询异常，使用缓存数据
        const data = getAllDept();
        if (data) {
          this.data = data;
        }
      }
    },
    chooseDept() {
      this.$emit("chooseDept", this.curDept);
    },
    cancel() {
      this.$emit("cancel");
    },
    // 选中部门信息[children: Array(0) , id: 6274, nodeKey: 3470, parentid: 1, selected: true,title: "中转站"]
    deptChange(deptArr, curDept) {
      // console.log("----", deptArr, curDept);
      this.curDept = {
        id: deptArr[0].id,
        title: deptArr[0].title,
      };
    },
  },
  watch: {
    showModal: function (val) {
      this.showChooseDept = this.showModal;
    },
  },
  created() {
    const userPm = getUserPm();
    if (userPm) {
      this.showDept = true;
      this.getPmUserTree();
    } else {
    }
  },
};
</script>
