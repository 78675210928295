<style scoped>
.userItem {
  text-align: center;
  background: white;
  width: 100%;
  margin: 10px auto;
  padding: 10px 2px;
  justify-content: space-between;
  box-shadow: 0px 3px 3px #1e9bcc0d;
}
.userItem .contentTitle {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.userItem .contentConMin {
  width: 100%;
  color: #a19393;
  text-align: left;
  padding-top: 4px;
  font-size: 0.8rem;
  display: flex;
}

.warning {
  background-color: #ff5722 !important;
  background-image: linear-gradient(
    to top,
    rgb(255, 152, 0) 0%,
    rgb(255, 87, 34) 100%
  ) !important;
}
.toUrlStyleTo {
  padding: 3px 10px;
  background: #5b7498;
  border-radius: 3px;
  color: white;
  /* font-size: 10px; */
}
.userSearch {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  z-index: 2001;
  background: white;
}
.userSearch .back {
  height: auto;
}
</style>
<template>
  <div class="userSearch">
    <search-top-com
      placeholder="手机号或姓名"
      :needEmit="true"
      @searchBack="searchBack"
      @search="searchCon"
    >
    </search-top-com>
    <loading-cmp v-if="showLoad"></loading-cmp>
    <div style="text-align: left; margin: 54px 10px 10px">
      <div v-for="(item, index) in listCon" :key="index" class="userItem">
        <div style="display: flex">
          <div class="contentTitle" style="flex: 1">
            {{ item.name }}
            <span v-if="item.status !== 1" style="font-size: 8px">
              <!-- 1=已激活，2=已禁用，4=未激活，5=退出企业 -->
              (<span
                class="mainTitleTap3"
                style="color: #515a6e"
                v-if="item.status == 2"
                >已禁用</span
              >
              <span
                style="color: #866060"
                class="mainTitleTap4"
                v-if="item.status == 4"
                >未激活</span
              >
              <span
                class="mainTitleTap5"
                style="color: silver"
                v-if="item.status == 5"
                >退出企业</span
              >)
            </span>
          </div>
          <div class="toUrlStyleTo" @click="chooseItem(item)">选择</div>
        </div>

        <div
          v-for="(deptItem, deptIndex) in item.deptList"
          :key="deptIndex"
          style="color: #808695; font-size: 12px; text-align: left"
        >
          {{ deptItem.dept_2nd_name }} / {{ deptItem.dept_3nd_name }}
        </div>
      </div>
      <load-more
        @refresh="onRefresh"
        :load-status="isLoadMore"
        :isShow="loadMoreShow"
      ></load-more>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { wxConPost } from "../api/httpApi";
import loadMore from "./loadMoreData.vue";
import searchTopCom from "./searchTopCom.vue";
import loadingCmp from "./loadingCmp.vue";

export default {
  name: "userList",
  data() {
    return {
      deptInfoList: [],
      listCon: [
        // {
        //   company: "衡阳车务段",
        //   deptId: 79,
        //   deptIdArr: [79],
        //   deptName: "许家洞站",
        //   education: "高中",
        //   entryTime: "1983.10",
        //   gender: "0",
        //   idCard: "432801196605031096",
        //   jobTitle: "站调",
        //   mobile: "13975740168",
        //   name: "\t刘桂郴",
        //   partyTitle: "",
        //   pmIndex: "0",
        //   pmName: null,
        //   pmValue: null,
        //   politicalStatus: "中共党员",
        //   post: "",
        //   status: 1,
        //   subSys: "车务",
        //   updated: "2021-07-21T07:52:29.156Z",
        //   userid: "15444337776193975d8161aee83b360852dacd3a069722306c22e58",
        //   workingPlace: "湖南郴州",
        // },
        // {
        //   company: "衡阳车务段",
        //   deptId: 79,
        //   deptIdArr: [79],
        //   deptName: "许家洞站",
        //   education: "高中",
        //   entryTime: "1983.10",
        //   gender: "0",
        //   idCard: "432801196605031096",
        //   jobTitle: "站调",
        //   mobile: "13975740168",
        //   name: "\t刘桂郴",
        //   partyTitle: "",
        //   pmIndex: "0",
        //   pmName: null,
        //   pmValue: null,
        //   politicalStatus: "中共党员",
        //   post: "",
        //   status: 2,
        //   subSys: "车务",
        //   updated: "2021-07-21T07:52:29.156Z",
        //   userid: "15444337776193975d8161aee83b360852dacd3a069722306c22e58",
        //   workingPlace: "湖南郴州",
        // },
        // {
        //   company: "衡阳车务段",
        //   deptId: 79,
        //   deptIdArr: [79],
        //   deptName: "许家洞站",
        //   education: "高中",
        //   entryTime: "1983.10",
        //   gender: "0",
        //   idCard: "432801196605031096",
        //   jobTitle: "站调",
        //   mobile: "13975740168",
        //   name: "\t刘桂郴",
        //   partyTitle: "",
        //   pmIndex: "0",
        //   pmName: null,
        //   pmValue: null,
        //   politicalStatus: "中共党员",
        //   post: "",
        //   status: 4,
        //   subSys: "车务",
        //   updated: "2021-07-21T07:52:29.156Z",
        //   userid: "15444337776193975d8161aee83b360852dacd3a069722306c22e58",
        //   workingPlace: "湖南郴州",
        // },
        // {
        //   company: "衡阳车务段",
        //   deptId: 79,
        //   deptIdArr: [79],
        //   deptName: "许家洞站",
        //   education: "高中",
        //   entryTime: "1983.10",
        //   gender: "0",
        //   idCard: "432801196605031096",
        //   jobTitle: "站调",
        //   mobile: "13975740168",
        //   name: "\t刘桂郴",
        //   partyTitle: "",
        //   pmIndex: "0",
        //   pmName: null,
        //   pmValue: null,
        //   politicalStatus: "中共党员",
        //   post: "",
        //   status: 5,
        //   subSys: "车务",
        //   updated: "2021-07-21T07:52:29.156Z",
        //   userid: "15444337776193975d8161aee83b360852dacd3a069722306c22e58",
        //   workingPlace: "湖南郴州",
        // },
      ],
      limit: 20,
      loadMoreShow: false,
      isLoadMore: true,
      searchVal: "",
      showLoad: false,
    };
  },
  components: { loadMore, searchTopCom, loadingCmp },

  props: {
    isNeedMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async getUserList() {
      try {
        if (this.searchVal) {
          this.showLoad = true;
          const ret = await wxConPost(
            "/services/gttt-wxstats-mgt/user/searchUser",
            {
              search: this.searchVal,
              page: 1,
              isNeedMobile: this.isNeedMobile,
              limit: this.limit,
            }
          );
          const listCon = ret.ret ? ret.ret : [];
          this.deptInfoList = ret.deptInfoList ? ret.deptInfoList : [];
          const curData = this.dealData(listCon);
          this.listCon = curData;

          if (ret.ret.length == 0 || ret.ret.length < this.limit) {
            this.isLoadMore = false;
            this.loadMoreShow = true;
            this.showLoad = false;
            return;
          }
          this.showLoad = false;
          // 显示加载
          this.isLoadMore = true;
          this.loadMoreShow = true;
        } else {
          this.$Message.info("请输入手机号或姓名");
        }
      } catch (error) {
        this.showLoad = false;
        console.log("----", error);
      }
    },
    // loadMore
    async onRefresh() {
      const query = {
        search: this.searchVal,
        limit: this.limit,
        isNeedMobile: this.isNeedMobile,
        page: Math.floor(this.listCon.length / this.limit) + 1,
      };

      try {
        if (this.searchVal) {
          this.showLoad = true;
          const ret = await wxConPost(
            "/services/gttt-wxstats-mgt/user/searchUser",
            query
          );

          if (ret.ret.length == 0 || ret.ret.length < this.limit) {
            this.isLoadMore = false;
            this.loadMoreShow = true;
            this.showLoad = false;
            return;
          }
          this.showLoad = false;
          const listCon = ret.ret ? ret.ret : [];
          this.deptInfoList = ret.deptInfoList ? ret.deptInfoList : [];
          const curData = this.dealData(listCon);
          this.listCon = this.listCon.concat(curData);

          // 显示加载
          this.isLoadMore = true;
          this.loadMoreShow = true;
        }
      } catch (error) {
        console.log("----", error);
      }
    },
    dealData(listCon) {
      try {
        let _listCon = listCon;
        const deptInfo = _.groupBy(this.deptInfoList, "deptId");

        console.log("---", _listCon);
        for (let iterator of _listCon) {
          //  dept_2nd_id: 2983
          // dept_2nd_name: "长沙车站"
          // dept_3nd_id: 2983
          // dept_3nd_name: "长沙车站"

          const deptList = [];
          for (const deptItem of iterator.deptIdArr) {
            const _itemdeptInfo = deptInfo[deptItem];
            deptList.push({
              dept_2nd_name:
                _itemdeptInfo && _itemdeptInfo[0]
                  ? _itemdeptInfo[0].dept_2nd_name
                  : "",
              dept_3nd_name:
                _itemdeptInfo && _itemdeptInfo[0]
                  ? _itemdeptInfo[0].dept_3nd_name
                  : "",
            });
          }
          iterator = Object.assign(iterator, { deptList });
        }
        return _listCon;
      } catch (error) {
        console.log("error----", error);
        return [];
      }
    },

    searchCon(searchVal) {
      this.searchVal = searchVal;
      this.listCon = [];
      this.page = 1;
      if (this.searchVal) {
        this.getUserList();
      } else {
        this.$Message.info("请输入手机号或姓名");
      }
    },

    chooseItem(data) {
      if (data.birthday) {
        data["age"] = moment().diff(data.birthday, "years");
      }
      this.$emit("chooseItem", data);
    },

    searchBack() {
      this.$emit("searchBack");
    },
  },
  watch: {},
  created() {
    if (this.searchVal) {
      this.getUserList();
    }
  },
};
</script>
